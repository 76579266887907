import React from "react";
import { Form, Field } from "react-final-form";

export default class ContactForm extends React.Component {
  state = {
    formSuccess: false,
    formFail: false,
  };

  onSubmit = async (values) => {
    // window.alert(JSON.stringify(values, 0, 2));
    return fetch(
      "https://dqzt5p3pq9.execute-api.ap-southeast-1.amazonaws.com/prod/",
      {
        mode: "no-cors",
        method: "POST",
        body: JSON.stringify(values),
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      }
    )
      .then((res) => {
        this.setState({ formSuccess: true });
      })
      .catch((err) => {
        this.setState({ formFail: true });
      });
  };

  render() {
    return (
      <div className="contact-card">
        <h4>Get in touch with us</h4>
        <Form
          onSubmit={this.onSubmit}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }
            if (!values.email) {
              errors.email = "Required";
            }
            if (!values.message) {
              errors.message = "Required";
            }
            return errors;
          }}
          initialValues={{}}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label for="name">Full Name</label>
                <Field name="name">
                  {({ input, meta }) => (
                    <div>
                      <input {...input} type="text" className="form-control" />
                      {meta.error && meta.touched && (
                        <span className="error">{meta.error}</span>
                      )}
                    </div>
                  )}
                </Field>
              </div>
              <div className="form-group">
                <label for="email">Email</label>
                <Field name="email">
                  {({ input, meta }) => (
                    <div>
                      <input {...input} type="email" className="form-control" />
                      {meta.error && meta.touched && (
                        <span className="error">{meta.error}</span>
                      )}
                    </div>
                  )}
                </Field>
              </div>
              <div className="form-group">
                <label>
                  Phone<span> (optional)</span>
                </label>
                <Field name="telephone">
                  {({ input, meta }) => (
                    <div>
                      <input {...input} type="tel" className="form-control" />
                      {meta.error && meta.touched && (
                        <span className="error">{meta.error}</span>
                      )}
                    </div>
                  )}
                </Field>
              </div>
              <div className="form-group">
                <label>Message</label>
                <Field name="message">
                  {({ input, meta }) => (
                    <div>
                      <textarea
                        {...input}
                        type="text"
                        className="form-control"
                      ></textarea>
                      {meta.error && meta.touched && (
                        <span className="error">{meta.error}</span>
                      )}
                    </div>
                  )}
                </Field>
              </div>
              <button
                className="default-btn no-icon"
                type="submit"
                disabled={submitting}
              >
                Send
              </button>
              <div
                className={
                  this.state.formSuccess
                    ? "form-msg success show"
                    : "form-msg success"
                }
              >
                <span>
                  Thanks for your message! We'll be in touch with you shortly.
                </span>
              </div>
              <div
                className={
                  this.state.formFail ? "form-msg fail show" : "form-msg fail"
                }
              >
                <span>
                  There was a problem sending your message. Please try again.
                </span>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

{
  /* <div className="contact-card">
      <h4>Get in touch with us</h4>
      <div className="form-group">
        <label>Full Name</label>
        <input className="form-control"></input>
      </div>
      <div className="form-group">
        <label>Email</label>
        <input className="form-control"></input>
      </div>
      <div className="form-group">
        <label>
          Phone<span> (optional)</span>
        </label>
        <input className="form-control"></input>
      </div>
      <div className="form-group">
        <label>Message</label>
        <textarea className="form-control"></textarea>
      </div>
      <button className="default-btn no-icon">Send</button>
    </div> */
}
